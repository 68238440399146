.SESelect{
    .SESelect__control{
        border: 1px solid #D7E0E8;
        background-color: transparent;
        min-height: 20px;
        height: 40px;
        background: transparent;
    }
    
    .SESelect__indicator{
        padding: 5px;
    }
    .SESelect__indicator-separator{
        background-color: hsla(0, 0%, 80%, 0.22);
    }
}
.SESelect.SESelectSmall{
    .SESelect__control{
        
        height: 30px;
        
    }
}