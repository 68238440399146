.changelogLink{
    display: flex;
    color: rgb(161, 168, 185);
    
}
#HW_badge_cont{
    width: 16px !important;
    height: 16px !important;
    font-size: 0.8em;
    margin-left: 8px !important;
}
#HW_badge{
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    
}