.quill{
    .ql-container{
        font-size: 1em;
    }
    .ql-editor{
        min-height: 300px;
        padding: 0px 0px;
    }
    .ql-container.ql-snow {
        border-radius: 0px 0px 3px 3px;
    }
    .ql-toolbar.ql-snow{
        border-radius: 3px 3px 0px 0px;
    }
}