@import "../../node_modules/react-grid-layout/css/styles.css";
@import "../../node_modules/react-resizable/css/styles.css";
@import "./datetime-picker.scss";
@import "./changelog.scss";
@import "./select.scss";
@import "./quill.scss";
@import "~@blueprintjs/core/lib/scss/variables";
@import "./calendar.scss";
$pt-intent-primary: #0D81F1;
$blue2: #228be6;
$blue1: #0D81F1;
$pt-input-box-shadow: 0px;
// $input-background-color: transparent;
// $pt-input-box-shadow: inset border-shadow(0.15),
//                       inset 0 1px 1px rgba($black, $pt-drop-shadow-opacity) !default;
$pt-elevation-shadow-0: 0px;
$pt-elevation-shadow-1: 0px;
$pt-elevation-shadow-2: 0px;
$pt-elevation-shadow-3: 0px;
$pt-elevation-shadow-4: 0px;
$pt-dark-elevation-shadow-0: 0px;
$pt-dark-elevation-shadow-1: 0px;
$pt-dark-elevation-shadow-2: 0px;
// $pt-dark-elevation-shadow-3: 0px;
$pt-dark-elevation-shadow-4: 0px;
// $pt-intent-primary: #258FFB;
$button-border-width: 0px;
$dark-card-background-color: #2a3844;
// #f1f3f5
$svg-icon-map: (
        '16px/small-minus.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23fff'/",
        '16px/small-tick.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='%23fff'/",
        '16px/chevron-right.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 0 0-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235C7080'/",
        '16px/more.svg': "g fill='%235C7080'%3E%3Ccircle cx='2' cy='8.03' r='2'/%3E%3Ccircle cx='14' cy='8.03' r='2'/%3E%3Ccircle cx='8' cy='8.03' r='2'/%3E%3C/g",
);

@function svg-icon($inline-svg, $fill-color) {
  @return url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3C" + map-get($svg-icon-map, $inline-svg) + "%3E%3C/svg%3E")
}
@import "~@blueprintjs/core/src/blueprint.scss";
html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    // background-color: $dark-gray4;
    // font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    //   text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    color: #868e96;
    background-color: #f2f4fa;
}

#root {
    width: 100%;
    height: 100%;
}

.theme {
    background-color: rgb(251, 253, 255);
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 1;
    transition: background-color .1s cubic-bezier(.4, 1, .75, .9);
}

.theme.bp3-dark {
    background-color: $dark-gray4;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: #258FFB;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.bp3-dark .bp3-button.bp3-minimal {
    color: #f5f8fa;
}

// .bp3-card{
//   border: 0px;
// }
// .bp3-card{
//   position: relative;
// }
// .bp3-card:after{
//   content: "";
//   display: block;
//   width: calc(100% - 40px);
//   margin: auto;
//   position: absolute;
//   bottom: 0px;
//   height: 20px;
//   box-shadow: 0 1px 50px 0 rgba(168,168,168,0.50);
//   // background: rebeccapurple;
//   z-index:-1;
// }
.#{$ns}-input {
    background: #f8f9fa;
}

.#{$ns}-card {
    position: relative;
    // &.#{$ns}-dark,
    // .#{$ns}-dark & {
    //   box-shadow: $pt-dark-elevation-shadow-0;
    //   background-color: $dark-card-background-color;
    // }
    // &:not(:active){
    &.#{$ns}-interactive:active {
        opacity: 1;
    }
    &:after {
        content: "";
        display: block;
        width: calc(100% - 20px);
        margin: auto;
        position: absolute;
        bottom: 0px;
        height: 90%;
        box-shadow: 0px 5px 30px 0px rgba(69, 79, 95, 0.13);
        z-index: -1;
        left: 10px;
    }
    &.#{$ns}-dark,
    .#{$ns}-dark & {
        &:after {
            box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.5);
        }
    }
    // }
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.gutter.gutter-vertical {
    // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
}

.flip span {
    transform: rotate(90deg);
}

.projectItem {
    background: white;
    border-radius: 5px;
    padding: 8px;
    &.#{$ns}-dark,
    .#{$ns}-dark & {
        color: rgba(255, 255, 255, 0.5);
        background-color: $dark-card-background-color;
    }
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
    -webkit-overflow-scrolling: auto;
    width: 8px;
    background-color: rgba(255, 255, 255, 0.2);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, .2);
}

.react-grid-item.react-grid-placeholder {
    background: black;
}

.kanbanColumn {
    height: 100%;
    width: 100%;
    padding: 0.8em;
    background-color: #F4F8FB;
}

.bp3-navbar.bp3-dark,
.bp3-dark .bp3-navbar {
    background-color: #1C84EE;
}

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon,
.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard,
.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large {
    color: #ffffff;
}

.listItemContainer {
    // padding-top: 0.3em;
    padding-bottom: 0.6em;
}

.listItem {
    padding: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    cursor: pointer;
    color: #666F8B;
}
.listItemInfo{
    color: #8D9BBE;
    text-transform: uppercase;
    font-size:0.7em;
}
.listItemMember{
    width: 20px;
    height: 20px;
    font-size: 0.7em;
    color: #95A2C1;
    // border: 1px solid #8D9BBE;
    background-color: #F2F6F9;
    // background-image: url('https://api.adorable.io/avatars/64/ck6flx2mf0000evpdlyda61cv.png');
    background-size: cover;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
    padding-top: 1px;
}
.listItemMemberPopover{
    padding: 1em;
}
.itemTag{
    padding: 0.35em 0.6em;
    border-radius: 3px;
    font-size: 0.7em;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
}
.itemSpace{
    padding: 0.8em 0em;
}

.navBarBlue{
    background-color: #0D81F1;
    .bp3-button-text{
        color: white;
    }
    .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large{
        color: white;
    }
    .search span{
        color: white;
    }
    .search input{
        background-color: #2b92f5;
        border: 0px;
        color: white;
        &::placeholder{
            color: white;
        }   
    }
}
.menuVoice{
    color: #A1A8B9;
    text-transform: uppercase;
    font-size: 0.9em;
    margin-bottom: 0;
    padding: 1em;
    padding-left: 1em;
    margin-top: 1em;
}
.menuSubVoice{
    padding: 0.6em;
    padding-left: 2em;
    color: #565E73;
    display: flex;
    cursor: pointer;
    .bp3-icon{
        color: #A1A8B9;
    }
    &.active{
        background-color: #e8edf0;
    }
}

.bp3-input-group > .bp3-icon{
    color: #a0a8b9;
}

.bp3-input {
    background: #fff;
    border: 1px solid #D7E0E8;
}
.diagonalMenu{
    position: relative;
}
.diagonalMenu::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 50px solid transparent;
    border-left: 30px solid rgb(242, 247, 250);
    width: 0;
}


.bp3-control.bp3-checkbox .bp3-control-indicator{
    border: 1px solid #cacaca;
}
.bp3-control input:checked ~ .bp3-control-indicator{
    border: 0px;
}
.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator{
    border: 0px;
}


.bp3-button {
     padding: 10px 16px;
    .bp3-button-text{
     
        margin-top: -2px;
    }
    &.bp3-intent-primary {
        box-shadow: none;
        background-color: #0c81f1;
        background-image: none;
        color: #ffffff;
    }
    &.bp3-intent-success{
        background-image: none;
        box-shadow: none;
    }
    &.bp3-intent-danger{
        background-image: none;
        box-shadow: none;
    }
    border: 1px solid transparent;
    &:not([class*="bp3-intent-"]):not([class*="bp3-minimal"]){
        box-shadow: none;
        background-image: none;
        background-color: transparent;
        background-color: rgba(0,0,0,0.1);
        // border: 1px solid #565E73;
        // color: #565E73;
        .bp3-icon{
            color: #565E73;
        }
    }
}
.bp3-input-group{
    .bp3-input{
        height: 38px;
    }
    & > .bp3-icon, .bp3-input-group .bp3-input-action > .bp3-spinner {
        margin: 11px;
    }
}

.tagWithIcon{
    padding: 6px 10px;
    
}
.tagWithIcon.bp3-tag.bp3-round{
    padding-right: 10px;
    padding-left: 10px;
}
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
   
    color: #676869;
}

.bp3-html-select select, .bp3-select select{
    height: 40px;
    background-image: none;
    box-shadow: none;
    
}
.bp3-html-select .bp3-icon, .bp3-select .bp3-icon, .bp3-select::after{
    top: 14px;
}